import { format } from 'date-fns'

const date = {
  getFormattedDate (str) {
    const x = new Date()
    let date = ''
    if (str) {
      try {
        const attributeValueDate = new Date(str)
        const utc = attributeValueDate.getTime() + (x.getTimezoneOffset() * 60000)
        const nd = new Date(utc + (3600000 * 5))
        date = format(nd, 'dd.MM.yyyy HH:mm')
      } catch (e) {
        date = str
      }
    }
    return date
  }
}

export default date
