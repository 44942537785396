<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <h2 class="page-title">Договора</h2>
    </div>
    <el-card style="margin-bottom: 15px;">
      <el-row type="flex" class="row-bg" justify="space-between">
        <div>
          <el-date-picker
              v-model="dateRange"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="По"
              start-placeholder="Начало"
              end-placeholder="Конец"
              @change="getData"
              :picker-options="pickerOptions"
              :default-value="defaultDateRange">
          </el-date-picker>
        </div>
<!--        <el-col :span="6">-->
<!--          <el-input v-model="search" placeholder="Поиск"></el-input>-->
<!--        </el-col>-->
      </el-row>
    </el-card>
    <el-card>
      <el-table
          v-loading="isLoading"
          :data="filteredData.filter(data => !search || data.docNumber.includes(search))"
          @sort-change="sortChange"
          @row-click="openDocument($event)"
          :default-sort="{}"
          size="small"
          style="width: 100%">
        <el-table-column
            prop="documentId"
            label="Id документа"
            sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.row.meta.documentId }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="sendedAt"
            label="Дата отправки"
            sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.row.created }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="statusName"
            label="Статус"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.statusName }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 25, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalElements"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import blockchainApi from '@/modules/api/blockchain'
import authorizationAPI from '@/modules/api/authorization'
import date from '@/modules/directories/date/date'
import { canCreateTickets } from '@/modules/permissions/permissions'
import { endOfDay, format, startOfMonth } from 'date-fns'

export default {
  name: 'CompanyAgreement',
  components: {
  },
  data () {
    const today = new Date()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth()
    const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1)
    const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1)
    const currentData = new Date()
    const fromDate = startOfMonth(currentData)
    const toDate = currentData
    return {
      isLoading: false,
      data: [],
      createDialogVisible: false,
      creationDocType: '',
      currentPage: 1,
      pageSize: 10,
      totalElements: 0,
      sortBy: '',
      orderBy: '',
      search: '',
      notificantionCount: 0,
      pickerOptions: {
        shortcuts: [{
          text: 'Неделя',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Месяц',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 месяца',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultDateRange: [firstDayOfPreviousMonth, firstDayOfCurrentMonth],
      value1: '',
      dateRange: [
        fromDate,
        toDate
      ]
    }
  },
  created () {
  },
  mounted () {
    this.getData()
  },
  computed: {
    getCompanyBin () {
      return authorizationAPI.getUsersCompanyBin()
    },
    canCreateTickets () {
      return canCreateTickets(authorizationAPI.getRole())
    },
    isRoleErtys () {
      return authorizationAPI.isRoleErtys()
    },
    isRoleKazykurt () {
      return authorizationAPI.isRoleKazykurt()
    },
    getUsersCompanyBin () {
      return authorizationAPI.getUsersCompanyBin()
    },
    filteredData () {
      return this.data
    },
    getDocumentTypes () {
      if (this.$route.params.types) {
        return this.$route.params.types.split(',')
      }
      return []
    }
  },
  watch: {
  },
  methods: {
    sortChange ({ column, prop, order }) {
      console.log(prop, order)
      this.sortBy = prop
      if (order && order.includes('asc')) {
        this.orderBy = 'asc'
      } else if (order && order.includes('desc')) {
        this.orderBy = 'desc'
      } else {
        this.orderBy = ''
        this.sortBy = ''
      }
      this.getData()
    },
    handleSizeChange (event) {
      this.pageSize = event
      this.getData()
      console.log(event)
    },
    handleCurrentChange (event) {
      this.currentPage = event
      this.getData()
      console.log(event)
    },
    getData () {
      this.isLoading = true
      const fromDate = format(new Date(this.dateRange[0]), 'yyyy-MM-dd HH:mm:ss')
      const toDate = format(endOfDay(new Date(this.dateRange[1])), 'yyyy-MM-dd HH:mm:ss')
      blockchainApi.getDocumentsPaginated(fromDate, toDate, this.currentPage, this.pageSize, this.getDocumentTypes, this.sortBy, this.orderBy, '').then(resp => {
        this.totalElements = resp.total
        this.data = resp.data.map(item => {
          item.created = item.createdAt ? date.getFormattedDate(item.createdAt) : ''
          if (item.status) {
            if (item.status.status === 'CREATED') {
              item.statusName = 'Создан'
            } else if (item.status.status === 'SENDED') {
              item.statusName = 'Отправлен'
            } else if (item.status.status === 'APPROVED') {
              item.statusName = 'Утвержден'
            } else if (item.status.status === 'RECEIVED') {
              item.statusName = 'Доставлен'
            } else if (item.status.status === 'REJECTED') {
              item.statusName = 'Отклонен'
            } else if (item.status.status === 'CANCELED') {
              item.statusName = 'Отменен'
            } else if (item.status.status === 'UPDATED') {
              item.statusName = 'Отредактирован'
            } else if (item.status.status === 'CONFIRMED') {
              item.statusName = 'Подтвержден'
            } else if (item.status.status === 'SIGNED') {
              item.statusName = 'Подписан'
            }
          }
          return item
        })
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.isLoading = false
      })
    },
    openDocument (data) {
      this.$router.push({ path: '/company/agreement-detail', query: { hash: data.hash } })
    }
  }
}
</script>

<style>
.pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center
}
.el-table tr {
  cursor: pointer;
}
.el-table .error-row {
  background-color: #ffd8d8;
}
</style>
