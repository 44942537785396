export default {
  data: [
    '',
    {
      id: 628,
      title: 'Авто',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'avto',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 893,
      title: 'Авто Дт КЗХ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'avtodtkzh',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 632,
      title: 'Авто КЗХ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'avtokzh',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 682,
      title: 'Авто ЭКС МЛ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'avtoeksml',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 631,
      title: 'Авто экспорт',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 641,
      title: 'Авто/ДТ КЗХ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'avtodtkzh',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 659,
      title: 'Авто/ДТ ЭКС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'avtodteks',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 683,
      title: 'Авто/ДТ ЭКС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'avtodteks',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 684,
      title: 'Авто/ДТ ЭКС ср.Азия',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 724,
      title: 'Автобензин',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 612,
      title: 'Бензол',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'benzene',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 3,
        title: 'Нефтехимия',
        shortTitle: 'нефтехимия',
        key: 'neftehim'
      }
    },
    {
      id: 640,
      title: 'ВГ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 606,
      title: 'ВГ НТС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 685,
      title: 'ВГ Новороссийск',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 686,
      title: 'ВГ ЭКС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 619,
      title: 'ВГ экс НТС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 626,
      title: 'ВГ-ТМ НТС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'vgtmnts',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 611,
      title: 'ВГ-ТМ Новоросийск',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 609,
      title: 'ВГ-ТМ ЭКС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'vgtmeks',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 618,
      title: 'ВГ/ТМ КЗХ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 687,
      title: 'ВГ/ТМ Таганрог ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 620,
      title: 'ВГ/ТМ экс НТС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 723,
      title: 'Вакуум газойль',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 1364,
      title: 'Вес проверочный',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: '',
      receiveMailing: false,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 900,
      title: 'Газ.конденсат',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 688,
      title: 'Газовый конденсат',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 710,
      title: 'Груженный',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 627,
      title: 'ДТ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'dt',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 604,
      title: 'ДТ ЭКС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'dteks',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 690,
      title: 'ДТ ЭКС Новороссийск',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 725,
      title: 'Дизельное топливо',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 630,
      title: 'Дт КЗХ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'dtkzh',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 689,
      title: 'Дт ЭКС МЛ (Новороссийск)',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 722,
      title: 'Кокс',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 639,
      title: 'Мазут',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 637,
      title: 'Метанол',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 636,
      title: 'Метиланилин ( ММА)',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 635,
      title: 'Метилтретбутиловый эфир ( МТБЭ)',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 638,
      title: 'Натрий',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 1449,
      title: 'Нефть',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'neft',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 853,
      title: 'ПВ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'gondola',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 899,
      title: 'ПВ МВСП',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'gondola',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 894,
      title: 'ПВ кокс',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'gondola',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 629,
      title: 'ПТ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'pt',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 621,
      title: 'ПТ КЗХ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'ptkzh',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 614,
      title: 'Параксилол',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'paraxylene',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 3,
        title: 'Нефтехимия',
        shortTitle: 'нефтехимия',
        key: 'neftehim'
      }
    },
    {
      id: 625,
      title: 'Пентано-гексановая фракция ( ПГФ)',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 691,
      title: 'Платформа',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'platform',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 709,
      title: 'Порожний',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 633,
      title: 'Пропан груженный',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 605,
      title: 'Пропан порожний',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 642,
      title: 'РТ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'rt',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 692,
      title: 'РТ/ТС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'rtts',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 855,
      title: 'Реактивное топливо',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 896,
      title: 'С-С',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 898,
      title: 'С-С (Бензол)',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 897,
      title: 'С-С (Параксилол)',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 880,
      title: 'СНО',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 699,
      title: 'Светлый',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 1,
        title: 'Светлый',
        shortTitle: 'св',
        key: 'light'
      }
    },
    {
      id: 895,
      title: 'Т-С',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 1024,
      title: 'Т-Т (через ГО)',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 634,
      title: 'ТМ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 610,
      title: 'ТМ - Новоросийск',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 617,
      title: 'ТМ КЗХ',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'tmkzh',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 693,
      title: 'ТМ КЗХ  Акжайык',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 694,
      title: 'ТМ КЗХ Аркалык',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 608,
      title: 'ТМ НТС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'tmnts',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 607,
      title: 'ТМ ЭКС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: 'tmeks',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 695,
      title: 'ТМ ЭКС Таганрог',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 615,
      title: 'ТМ Экспорт',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 616,
      title: 'ТМ Экспорт НТС',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    },
    {
      id: 854,
      title: 'Танк-контейнер',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 700,
      title: 'Темный',
      band: {
        version: 1,
        createdBy: 'demo',
        createdAt: '2020-10-01T14:27:25.843+0500',
        lastModifiedBy: 'demo',
        lastModifiedDate: '2020-10-01T14:40:24.916+0500',
        deletedAt: null,
        id: 31,
        title: 'Грузы',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: {
        id: 2,
        title: 'Тёмный',
        shortTitle: 'тем',
        key: 'dark'
      }
    }
  ]
}
