<template>
  <div>
    <el-card v-loading="isLoading">
      <el-page-header content="ВУ-20 / ВУ-20а" @back="goBack">
      </el-page-header>
      <el-divider></el-divider>
      <el-button native-type="submit" type="basic" @click="signDocument()" v-if="!isDocumentSignedByCompany && currentBase64 && canSignDocuments">
        Подписать
        <i class="el-icon-check" style="font-weight: bold"/>
      </el-button>
      <el-button native-type="submit" type="basic" @click="downloadSign()" v-if="canDownloadSign">
        Скачать подпись
        <i class="el-icon-check" style="font-weight: bold"/>
      </el-button>
      <el-button native-type="submit" type="basic" @click="downloadExcel()" v-if="currentExcel">
        Excel
        <i class="el-icon-download" style="font-weight: bold"/>
      </el-button>
      <el-divider></el-divider>
      <el-tabs v-model="activeName" class="is-flex is-justify-center" @tab-click="handleClick">
        <el-tab-pane label="Документ" name="1">
          <el-form ref="ruleForm" :model="docForm" label-position="top" label-width="80px"
                   style="margin-top: 10px;" @submit.native.prevent>
            <el-row>
              <el-col v-for="attr in attributes" :key="attr.value" :span="12">
                <el-form-item :label="attr.label" :prop="attr.value">
                  <el-input v-model="docForm[attr.value]" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Таблица" name="2">
          <el-table
              :data="tableData"
              size="small"
              style="width: 100%">
            <el-table-column
                v-for="attr in workplaceAttributes"
                :key="attr.value"
                :label="attr.label"
                :prop="attr.value"
            >
              <template slot-scope="scope">
                <el-input v-model="scope.row[attr.value]" readonly></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="История" name="3">
          <div class="history-table">
            <el-table
                :data="documentHistory"
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                style="width: 100%; cursor: pointer">
              <el-table-column
                  prop="meta.documentId"
                  label="Id документа">
              </el-table-column>
              <el-table-column
                  label="Номер документа">
                <template slot-scope="scope">
                  {{ scope.row.data.body ? scope.row.data.body.documentWorkplaceRailcar.documentRailcar.docNumber : '' }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="meta.sendedAt"
                  label="Дата отправления">
              </el-table-column>
              <el-table-column
                  prop="data.body.documentWorkplaceRailcar.documentRailcar.createdDate"
                  label="Дата cоздания">
                <template slot-scope="scope">
                  {{ scope.row.data.body ? getFormattedDate(scope.row.data.body.documentWorkplaceRailcar.documentRailcar.createdDate) : '' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Подписи" name="4">
          <el-card v-if="signData.length > 0" style="margin-top: 30px;">
            <h3>Информация об электронном документе</h3>
            <div class="sign-table">
              <table v-for="item in signData" :key="item.certificate" style="margin-bottom: 30px;">
                <tr>
                  <td>Результат проверки сертификата</td>
                  <td>{{ item.result }}</td>
                </tr>
                <tr>
                  <td>ИИН</td>
                  <td>{{ item.iin }}</td>
                </tr>
                <tr>
                  <td>Издатель</td>
                  <td>{{ item.issuer_name }}</td>
                </tr>
                <tr>
                  <td>ФИО</td>
                  <td>{{ item.name + ' ' + item.father_name }}</td>
                </tr>
                <tr>
                  <td>Серийный номер сертификата</td>
                  <td>{{ item.certificate }}</td>
                </tr>
                <tr>
                  <td>Срок действия сертификата</td>
                  <td>{{ item.time_from + ' - ' + item.until }}</td>
                </tr>
                <tr v-if="item.organization">
                  <td>Организация</td>
                  <td>{{ item.organization }}</td>
                </tr>
                <tr v-if="item.organization_bin">
                  <td>БИН организаций</td>
                  <td>{{ item.organization_bin }}</td>
                </tr>
              </table>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="Печатная форма" name="5">
          <el-card>
            <transition name="fade">
              <iframe
                  :src="base64Path"
                  width='100%' height="500"
              />
            </transition>
          </el-card>
        </el-tab-pane>
        <el-tab-pane v-if="isOpenDocument" label="Статусы" name="6">
          <div class="history-table">
            <el-table
                :data="statuses"
                :row-class-name="tableRowClassName"
                style="width: 100%; cursor: pointer">
              <el-table-column
                  prop="statusName"
                  label="Статус">
              </el-table-column>
              <el-table-column
                  prop="createdAt"
                  label="Дата">
                <template slot-scope="scope">
                  {{ scope.row.createdAt ? getFormattedDate(scope.row.createdAt) : '' }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="comment"
                  label="Комментарий">
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div style="margin-top: 20px;"></div>
      <el-button v-if="activeName !== '6'" native-type="submit" type="basic" @click="stepHandle((parseInt(activeName) + 1).toString())">
        Далее
        <i class="el-icon-arrow-right" style="font-weight: bold"/>
      </el-button>
      <el-button v-if="activeName !== '1'" native-type="submit" type="basic" @click="stepHandle((parseInt(activeName) - 1).toString())">
        <i class="el-icon-arrow-left" style="font-weight: bold"/>
        Назад
      </el-button>
    </el-card>
    <el-dialog :title="currentHistoryItem.hash" :visible.sync="historyDialog">
      <pre>{{ currentHistoryItem.meta }}</pre>
      <br>
      <pre>{{ currentHistoryItem.data }}</pre>
    </el-dialog>
  </div>
</template>

<script>
import companiesApi from '@/modules/api/companies'
import blockchainApi from '@/modules/api/blockchain'
import { MAIN_API } from '@/modules/api/apiUrl'
import { NCALayerClient } from 'ncalayer-js-client'
import signApi from '@/modules/api/sign'
import { format, parse } from 'date-fns'
import { canSignDocuments } from '@/modules/permissions/permissions'
import authorizationAPI from '@/modules/api/authorization'
// import signApi from '@/api/sign'

export default {
  name: 'CreateVu20',
  data () {
    return {
      signData: [],
      jasperPath: 'http://192.168.120.148:8080/jasperserver/rest_v2/reports/alterdoc/vu20qr/vu20qr.html?id=346215&username=jasperadmin&password=jasperadmin',
      workplaceAttributes: [
        {
          label: 'Номер вагона',
          value: 'nomerVagona'
        },
        {
          label: 'Ранее перевозимый груз',
          value: 'outGood20a'
        },
        {
          label: 'Операция по ГОСТу',
          value: 'gostOperation20a'
        },
        {
          label: 'Тип вагона',
          value: 'wagonСalibration'
        },
        {
          label: 'Примечание',
          value: 'primechaniyeVu'
        },
        {
          label: 'endTime20',
          value: 'endTime20'
        },
        {
          label: 'startTime20',
          value: 'startTime20'
        },
        {
          label: 'tripleValve',
          value: 'tripleValve'
        }
      ],
      attributes: [
        {
          label: 'Номер документа',
          value: 'docNumber'
        },
        {
          label: 'Дата создания документа',
          value: 'createdDate'
        },
        {
          label: 'Компания отправитель',
          value: 'senderCompany'
        },
        {
          label: 'Компания получатель',
          value: 'receiverCompany'
        },
        {
          label: 'Станция промывки-пропарки',
          value: 'station20a'
        },
        {
          label: 'Под погрузку/выгрузку',
          value: 'inGood20a'
        },
        {
          label: 'Путь',
          value: 'path'
        },
        {
          label: 'Тип обработки',
          value: 'processingType'
        },
        {
          label: 'На завод',
          value: 'toFactory'
        },
        {
          label: 'На перекачку',
          value: 'forPumpingVu20a'
        },
        {
          label: 'Сменный Мастер',
          value: 'master'
        },
        {
          label: 'Бригадир',
          value: 'brigadir'
        },
        {
          label: 'Оператор',
          value: 'operator'
        },
        {
          label: 'Старший пропарщик',
          value: 'seniorSteamer'
        },
        {
          label: 'Слесарь подвижного состава',
          value: 'locksmith'
        },
        {
          label: 'Приёмщик нефтеналивных ёмкостей',
          value: 'receiverOfOilTanks'
        },
        {
          label: 'Компания Собственник',
          value: 'companyOwner'
        },
        {
          label: 'Бригадир ТОР',
          value: 'brigadirTOR'
        },
        {
          label: 'Представитель РТС Оператор',
          value: 'ptc'
        },
        {
          label: 'Представитель ПКОП',
          value: 'utsl'
        }
      ],
      statusDialog: false,
      isLoading: false,
      documentInfo: {},
      companies: [],
      tableData: [
        { position: 1 }
      ],
      docForm: {
        docNumber: '',
        senderCompany: 1,
        receiverCompany: 8,
        date20a: new Date(),
        createdDate: '',
        station20a: '',
        inGood20a: '',
        path: '',
        processingType: '',
        toFactory: '',
        forPumpingVu20a: '',
        master: '',
        brigadir: '',
        operator: '',
        seniorSteamer: '',
        locksmith: '',
        receiverOfOilTanks: ''
      },
      statusForm: {
        status: '',
        comment: ''
      },
      senderBin: '',
      statuses: [],
      activeName: '1',
      documentId: '',
      docType: '',
      isDocumentSignedByCompany: false,
      canDownloadSign: false,
      documentHistory: [],
      historyDialog: false,
      currentHistoryItem: {},
      currentBase64: '',
      currentBase64Item: {},
      cmsCount: 0,
      currentExcelItem: {},
      currentExcel: ''
    }
  },
  mounted () {
    // this.getCompanies()
    if (this.isOpenDocument) {
      this.getDocumentByHash()
    }
    this.getFilteredHistory()
    // inGood20a.data.forEach(item => {
    //   console.log('item', item)
    // })
  },
  computed: {
    isUserKazykurt () {
      return authorizationAPI.isRoleKazykurt()
    },
    canSignDocuments () {
      return canSignDocuments(authorizationAPI.getRole())
    },
    base64Path () {
      return 'data:application/pdf;base64,' + this.currentBase64
    },
    isOpenDocument () {
      return !!this.$route.query.hash
    },
    getTypeOfVu20 () {
      if (this.tableData.length === 1) {
        return 'vu20a'
      }
      return 'vu20'
    },
    getHash () {
      return this.$route.query.hash
    },
    getUsername () {
      return JSON.parse(sessionStorage.getItem('loginUserInfo')).username
    },
    getUserCompanyBin () {
      return JSON.parse(sessionStorage.getItem('loginUserInfo')).company.bin
    },
    senderCompany () {
      if (this.senderBin === '150540023006') {
        return 'BATYS'
      } else if (this.senderBin === '070240009538') {
        return 'ERTYS'
      } else if (this.senderBin === '070240010234') {
        return 'KAZYKURT'
      }
      return ''
    }
  },
  watch: {
    senderBin (val) {
      if (this.senderCompany === 'BATYS') {
        this.attributes = this.attributes.filter(item => {
          return item.value !== 'companyOwner' && item.value !== 'inspectorPTO' && item.value !== 'brigadirTOR' && item.value !== 'ptc' && item.value !== 'stationTransceiver' && item.value !== 'utsl'
        })
        this.workplaceAttributes = this.workplaceAttributes.filter(item => {
          return item.value !== 'endTime20' && item.value !== 'startTime20' && item.value !== 'tripleValve'
        })
      } else if (this.senderCompany === 'ERTYS') {
        this.attributes = this.attributes.filter(item => {
          return item.value !== 'toFactory' && item.value !== 'forPumpingVu20a' && item.value !== 'brigadir' && item.value !== 'seniorSteamer' && item.value !== 'locksmith'
        })
      } else {
        this.attributes = this.attributes.filter(item => {
          return item.value !== 'date20a' && item.value !== 'toFactory' && item.value !== 'forPumpingVu20a' && item.value !== 'brigadir' && item.value !== 'operator' && item.value !== 'seniorSteamer' && item.value !== 'locksmith' && item.value !== 'receiverOfOilTanks'
        })
        this.workplaceAttributes = this.workplaceAttributes.filter(item => {
          return item.value !== 'primechaniyeVu'
        })
      }
    }
  },
  methods: {
    getStatuses () {
      const data = {
        hash: this.documentInfo.idHash
      }
      blockchainApi.getStatus(data).then((resp) => {
        this.statuses = resp.map(item => {
          if (item.status === 'CREATED') {
            item.statusName = 'Создан'
          } else if (item.status === 'SENDED') {
            item.statusName = 'Отправлен'
          } else if (item.status === 'APPROVED') {
            item.statusName = 'Утвержден'
          } else if (item.status === 'RECEIVED') {
            item.statusName = 'Доставлен'
          } else if (item.status === 'REJECTED') {
            item.statusName = 'Отклонен'
          } else if (item.status === 'CANCELED') {
            item.statusName = 'Отменен'
          } else if (item.status === 'UPDATED') {
            item.statusName = 'Отредактирован'
          } else if (item.status === 'CONFIRMED') {
            item.statusName = 'Подтвержден'
          }
          return item
        })
      }).catch(err => {
        console.log('err', err)
      })
    },
    getSign () {
      console.log(this.documentId)
      fetch(MAIN_API + '/api/podpis/get/cms/file?id=' + this.documentId)
        .then(resp => {
          console.log('resp', resp)
          return resp.blob()
        }).then(blob => {
          this.submitUpload(blob)
        })
    },
    submitUpload (file) {
      console.log('file', file)
      const data = new FormData()
      data.append('file', file, 'file')
      signApi.checkSign(data).then((resp) => {
        this.signData = JSON.parse(resp.body).map(item => {
          const dateFormat = 'dd.MM.yyyy HH:mm:ss'
          item.time_from = item.time_from.split(' ').slice(0, -1).join(' ')
          item.until = item.until.split(' ').slice(0, -1).join(' ')
          item.time_from_date = parse(item.time_from, dateFormat, new Date())
          item.until_date = parse(item.until, dateFormat, new Date())
          item.result = item.until_date > new Date() ? 'Успешно' : 'Срок действия сертификата истек'
          return item
        })
      }).catch(err => {
        console.log(err)
      })
      // this.$refs.upload.submit()
    },
    saveUpdatedBase64 () {
      const meta = this.currentBase64Item.meta
      meta.receiverBin = [meta.senderBin]
      meta.senderBin = '1111'
      meta.docType = meta.doctype
      meta.sendedAt = new Date()
      meta.statuses = ['CONFIRMED']
      const data = {
        meta,
        body: { base64: this.currentBase64 }
      }
      blockchainApi.createDocument(data).then(resp => {
        console.log(resp)
      }).catch(err => {
        console.log(err)
      })
    },
    getFilteredHistory () {
      blockchainApi.filterDocument(this.getHash)
        .then(resp => {
          if (resp && resp.length > 0) {
            this.documentHistory = resp.map(item => {
              if (this.isJson(item.data)) {
                // item.data = JSON.stringify(JSON.parse(item.data), null, 2)
                item.data = JSON.parse(item.data)
              } else {
                if (item.meta.extension.includes('cms')) {
                  this.cmsCount++
                  this.currentBase64Item = item
                  this.currentBase64 = item.data
                } else if (item.meta.extension.includes('xlsx')) {
                  this.currentExcelItem = item
                  this.currentExcel = item.data
                }
              }
              return item
            })
          }
        })
    },
    checkSigning () {
      signApi.checkSignAccessById(this.documentId).then(resp => {
        if (resp.documentId) {
          this.isDocumentSigned = true
          this.getSign()
        } else {
          this.isDocumentSigned = false
          if (this.currentBase64) {
            const data = {
              documentId: this.documentId,
              base64: this.currentBase64,
              docType: this.docType
            }
            signApi.saveSignDocument(data).then(() => {
              this.getSign()
            }).catch(err => {
              console.log('err', err)
            })
          }
        }
      })
    },
    downloadExcel () {
      console.log(this.documentInfo)
      const binaryData = atob(this.currentExcel)

      const arrayBuffer = new ArrayBuffer(binaryData.length)
      const byteArray = new Uint8Array(arrayBuffer)
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i)
      }

      const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = `${this.documentInfo.meta.documentId}.${this.documentInfo.meta.doctype}.xlsx`

      downloadLink.click()
    },
    downloadSign () {
      fetch(MAIN_API + '/api/podpis/get/cms/file?id=' + this.documentId)
        .then(resp => {
          return resp.blob()
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = this.docType + '_' + this.documentId + '.pdf.cms'
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(error => console.error('Error:', error))
    },
    signDocument () {
      const ncalayerClient = new NCALayerClient()
      console.log(this.currentBase64)
      ncalayerClient.connect().then(async () => {
        const signatureResult = await ncalayerClient.createCMSSignatureFromBase64('PKCS12', this.currentBase64, 'SIGNATURE', true)
        console.log(signatureResult)
        const data = {
          documentId: this.documentId,
          base64: signatureResult,
          docType: this.docType
        }
        signApi.saveSignDocument(data).then(async resp => {
          alert('Документ успешно подписан!')
          await this.saveUpdatedBase64()
          await this.addDocumentSigners()
          this.getDocumentByHash()
        }).catch(err => {
          alert('Не удалось подписать документ ', err)
        })
      }).catch(err => {
        console.log(err)
        alert('Запустите ncaLayer', err)
      })
    },
    addDocumentSigners () {
      const data = {
        documentId: this.documentId,
        companyBin: this.getUserCompanyBin
      }
      blockchainApi.addDocumentSigners(data).catch(err => {
        console.log(err)
      })
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.split(',')[1])
        reader.onerror = (error) => reject(error)
      })
    },
    getDocumentByHash () {
      this.isLoading = true
      blockchainApi.getDocumentByHash(this.getHash).then((resp) => {
        this.documentInfo = resp.data
        this.documentInfo.data = JSON.parse(this.documentInfo.data)
        this.senderBin = this.documentInfo.data.meta.senderBin
        this.documentId = this.documentInfo.data.meta.documentId
        this.docType = this.documentInfo.data.meta.docType
        this.docForm.docNumber = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.docNumber
        this.docForm.receiverCompany = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.receiverCompany
        this.docForm.senderCompany = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.senderCompany
        this.docForm.createdDate = this.getFormattedDate(this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.createdDate)

        this.docForm.date20a = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.date20a
        this.docForm.station20a = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.station20a
        this.docForm.inGood20a = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.inGood20a
        this.docForm.path = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.path
        this.docForm.processingType = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.processingType
        this.docForm.toFactory = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.toFactory
        this.docForm.forPumpingVu20a = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.forPumpingVu20a
        this.docForm.master = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.master
        this.docForm.brigadir = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.brigadir
        this.docForm.operator = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.operator
        this.docForm.seniorSteamer = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.seniorSteamer
        this.docForm.locksmith = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.locksmith
        this.docForm.receiverOfOilTanks = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.receiverOfOilTanks

        this.docForm.companyOwner = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.companyOwner
        this.docForm.inspectorPTO = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.inspectorPTO

        this.docForm.brigadirTOR = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.brigadirTOR
        this.docForm.ptc = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.ptc
        this.docForm.stationTransceiver = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.stationTransceiver
        this.docForm.utsl = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.attributeRequests.utsl

        if (this.documentInfo.data.body.documentWorkplaceRailcar.workplaceDocumnet.length > 0) {
          this.tableData = []
          this.documentInfo.data.body.documentWorkplaceRailcar.workplaceDocumnet.forEach(item => {
            const workplace = {
              position: item.position,
              nomerVagona: item.attributeRequest.nomerVagona,
              gostOperation20a: item.attributeRequest.gostOperation20a,
              outGood20a: item.attributeRequest.outGood20a,
              primechaniyeVu: item.attributeRequest.primechaniyeVu,
              wagonCalibration: item.attributeRequest.wagonCalibration,
              endTime20: item.attributeRequest.endTime20,
              startTime20: item.attributeRequest.startTime20,
              tripleValve: item.attributeRequest.startTime20
            }
            this.tableData.push(workplace)
          })
          this.tableData.sort((a, b) => a.position - b.position)
        }
        this.checkSigning()
        this.getStatuses()
        this.getDocumentSigners(this.documentId)
      }).finally(() => {
        this.isLoading = false
      })
    },
    getDocumentSigners (documentId) {
      blockchainApi.getDocumentSigners(documentId).then(resp => {
        if (resp && resp.length > 0) {
          if (resp.filter(item => item.companyBin === this.getUserCompanyBin).length > 0) {
            this.isDocumentSignedByCompany = true
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getCompanies () {
      companiesApi.getCompanies().then(resp => {
        this.companies = resp.filter(item => item.enable === true)
      })
    },
    getCompanyById (id) {
      return this.companies.filter(item => item.id === id)[0]
    },
    stepHandle (val) {
      this.activeName = val
    },
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    goBack () {
      this.$router.go(-1)
    },
    addRow () {
      let lastId = 0
      this.tableData.forEach(item => {
        lastId = item.position
      })
      lastId++
      this.tableData.push({ position: lastId })
    },
    deleteRow (data) {
      // console.log(data.row.id)
      this.tableData = this.tableData.filter(item => item.position !== data.row.position)
    },
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    getFormattedDate (str) {
      const x = new Date()
      let date = ''
      if (str) {
        try {
          const attributeValueDate = new Date(str)
          const utc = attributeValueDate.getTime() + (x.getTimezoneOffset() * 60000)
          const nd = new Date(utc + (3600000 * 5))
          date = format(nd, 'dd.MM.yyyy HH:mm')
        } catch (e) {
          date = str
        }
      }
      return date
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex === 0) {
        return 'success-row'
      }
      return ''
    },
    rowClick (val) {
      this.currentHistoryItem = val
      this.historyDialog = true
    }
  }
}
</script>

<style>

.el-table .success-row {
  background-color: #f0f9eb !important;
}
.sign-table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.sign-table td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.sign-table tr:nth-child(even) {
  background-color: #f3f3f3;
}

</style>
