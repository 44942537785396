export default {
  data: [
    '',
    {
      id: 912,
      title: 'Амангелді Ә.Б.'
    },
    {
      id: 914,
      title: 'Джиенбеков А.Г.'
    },
    {
      id: 915,
      title: 'Хобдабергенов Б.О'
    },
    {
      id: 913,
      title: 'Турлыбеков Б.Т'
    }
  ]
}
