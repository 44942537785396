export default {
  data: [
    '',
    {
      id: 753,
      title: 'ТОР'
    },
    {
      id: 750,
      title: 'Вагон на ГО или ХО'
    },
    {
      id: 751,
      title: 'Вагон на ХО'
    },
    {
      id: 750,
      title: 'Вагон на ГО'
    },
    {
      id: 753,
      title: 'ДрКР'
    },
    {
      id: 752,
      title: 'Тестовое действие'
    }
  ]
}
