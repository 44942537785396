export default {
  data: [
    '',
    {
      id: 696,
      title: 'На ГО (с-с)',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 879,
      title: 'На ГО (т-с)',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 866,
      title: 'На ГО (т-т).',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 454,
      title: 'ТОР',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: 'tor',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 678,
      title: 'К отправке',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 95,
      title: 'До выяснения обстоятельств',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 90,
      title: 'Груженный вагон',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 857,
      title: 'Не осмотренный.',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: 'neOsmotrennyi',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 93,
      title: 'Годный порожний вагон',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: 'godnyiPorozhnyi',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 91,
      title: 'Негодный вагон',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 706,
      title: 'Через ГО.',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 179,
      title: 'ВГ-Годный порожний вагон',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 452,
      title: 'ВРП',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: 'vrp',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 189,
      title: 'Вагон на XО',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 94,
      title: 'Вагон на ГО',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 677,
      title: 'Вагон на ГО под СНО',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 654,
      title: 'Вагон на ГО текущий',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: 'current',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 188,
      title: 'Вагон на ХO',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 861,
      title: 'Годный',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: 'godnyi',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 185,
      title: 'Годный порожний вагон кзх',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: 'godnyiPorozhnyi',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 878,
      title: 'Годный.',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: 'godnyi',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 856,
      title: 'Груженный.',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 865,
      title: 'До выяснения обстоятельств.',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 863,
      title: 'К отправлению.',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 176,
      title: 'Метанол',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 858,
      title: 'На ГО (с-с).',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 881,
      title: 'На ГО (т-с).',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 862,
      title: 'Не годный.',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 178,
      title: 'ПВ Кокс',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 679,
      title: 'ПВ не осмотрен',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: 'gondolaNeOsmotren',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 195,
      title: 'Параксилол',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 171,
      title: 'Пропан груженный',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 167,
      title: 'Пропан порожний',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 191,
      title: 'ТМ Новороссийск',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 177,
      title: 'ТМ/КЗХ',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 92,
      title: 'ТОР к отправке',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 860,
      title: 'ТОР.',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: 'tor',
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    },
    {
      id: 1164,
      title: 'Текущий',
      band: {
        version: 0,
        createdBy: 'root',
        createdAt: '2019-11-11T16:47:04.925+0500',
        lastModifiedBy: 'root',
        lastModifiedDate: '2019-11-11T16:47:04.925+0500',
        deletedAt: null,
        id: 6,
        title: 'Действия',
        isDeletable: false,
        key: null
      },
      key: null,
      inWords: null,
      binForContractor: null,
      email: null,
      receiveMailing: null,
      childBandNeispravnosti: null,
      productParentType: null
    }
  ]
}
